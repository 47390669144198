<template>
  <v-row v-if="isPageLoading" class="d-flex justify-center py-8">
    <v-progress-circular indeterminate />
  </v-row>
  <v-row v-else dense>
    <v-col>
      <h2>Análise Item por Item</h2>
      <h3>Procedimentos</h3>
      <v-simple-table>
        <thead>
          <tr>
            <th>Nome</th>
            <th>Valor Cobrança UND</th>
            <th>QTD.</th>
            <th>Desconto %</th>
            <th>Acrescimo %</th>
            <th>Valor Cobrança Final</th>
            <th>Valor Pagamento UND</th>
            <th>Valor Pagamento Equipe</th>
            <th>Custo Administrativo</th>
            <th>Custo Imposto</th>
            <th>Custo Total</th>
            <th>Resultado</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="evo in evolucoesAgrupadas" :key="evo.id">
            <td>{{ evo.procedimento?.procedimento }}</td> <!-- Nome -->
            <td>{{ formatReal((Number(evo.valor_cobranca_final) || 0) ) }}</td> <!-- Valor Cobrança Und -->
            <td>{{ evo.quantidade }}</td> <!-- QTD-->
            <td> {{ evo.desconto }}</td> <!-- Desconto-->
            <td> {{ evo.acrescimo }}</td> <!-- Acrescimo -->
            <td>{{ formatReal((Number(evo.valor_cobranca_final) || 0) * (evo.quantidade || 1)) }}</td> <!-- Valor Cobrança Final -->
            <td>{{ formatReal((Number(evo.valor_pagamento) || 0)) }}</td> <!-- Valor Pagamento UND -->
            <td>{{ formatReal((Number(evo.valor_pagamento) || 0) * (evo.quantidade || 1)) }}</td> <!-- Valor Pagamento -->
            <td>{{ formatReal(((Number(evo.valor_cobranca_final) || 0) * (item.custo_fixo || 0) / 100) * (evo.quantidade || 1)) }}</td> <!-- Custo Adm -->
            <td>{{ formatReal(((Number(evo.valor_cobranca_final) || 0) * (item.imposto || 0) / 100) * (evo.quantidade || 1)) }}</td> <!-- Imposto -->
            <td>{{ formatReal(
              (Number(evo.valor_pagamento) || 0) * (evo.quantidade || 1) +
              (Number(evo.valor_cobranca_final) || 0) * (item.imposto || 0) / 100 * (evo.quantidade || 1) +
              (Number(evo.valor_cobranca_final) || 0) * (item.custo_fixo || 0) / 100 * (evo.quantidade || 1)
            ) }}</td> <!-- Custo Total -->
            <td>{{ formatReal(
              (Number(evo.valor_cobranca_final) || 0) * (evo.quantidade || 1) -
              (
                (Number(evo.valor_pagamento) || 0) * (evo.quantidade || 1) +
                (Number(evo.valor_cobranca_final) || 0) * (item.imposto || 0) / 100 * (evo.quantidade || 1) +
                (Number(evo.valor_cobranca_final) || 0) * (item.custo_fixo || 0) / 100 * (evo.quantidade || 1)
              )
            ) }}</td> <!-- Resultado -->

          </tr>
        </tbody>
      </v-simple-table>
      <h3 v-if="taxas.length > 0" >Taxas</h3>
      <v-simple-table v-if="taxas.length > 0">
        <thead>
          <tr>
            <th>Nome</th>
            <th>Valor Cobrança UND</th>
            <th>Qtd.</th>
            <th>Desconto %</th>
            <th>Acrescimo %</th>
            <th>Valor Cobrança Final</th>
            <th>Custo do Taxa UND</th>
            <th>Custo do Taxa Final</th>
            <th>Custo Administrativo</th>
            <th>Custo Imposto</th>
            <th>Custo Total</th>
            <th>Resultado</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="tx in taxas" :key="tx.id">
            <td>{{ tx.taxa.taxa.nome }}</td>  <!-- Nome -->
            <td>{{ formatReal((Number(tx.valor_cobranca_taxa) || 0) ) }}</td> <!-- Valor Cobrança UND -->
            <td>{{ tx.quantidade_taxa }}</td>  <!-- Qtd -->
            <td> {{ tx.desconto_taxa }}</td> <!-- Desconto-->
            <td> {{ tx.acrescimo_taxa }}</td> <!-- Acrescimo -->
            <td>{{ formatReal((Number(tx.valor_cobranca_final_taxa) || 0) ) }}</td> <!-- Valor Cobrança Final -->
            <td>{{ formatReal((Number(tx.taxa.taxa.custo) || 0) ) }}</td> <!-- Custo do Equipamento UND -->
            <td>{{ formatReal((Number(tx.taxa.taxa.custo) || 0) * (Number(tx.quantidade_taxa) || 0)) }}</td> <!-- Custo do Equipamento -->
            <td>{{ formatReal((Number(tx.valor_cobranca_final_taxa) || 0) * (Number(item.custo_fixo) || 0) / 100) }}</td><!-- Custo Administrativo -->
            <td>{{ formatReal((Number(tx.valor_cobranca_final_taxa) || 0) * (Number(item.imposto) || 0) / 100) }}</td>  <!-- Custo Imposto -->
            <td>{{ formatReal(
                  ((Number(tx.taxa.taxa.custo) || 0) * (Number(tx.quantidade_taxa) || 0)) +
                (((Number(tx.valor_cobranca_final_taxa) || 0) ) * (Number(item.custo_fixo) || 0) / 100) +
                (((Number(tx.valor_cobranca_final_taxa) || 0) ) * (Number(item.imposto) || 0) / 100)
            ) }}</td> <!-- Custo Total -->
            <td>{{ formatReal(
                ((Number(tx.valor_cobranca_final_taxa) || 0) ) -
                (
                  ((Number(tx.taxa.taxa.custo) || 0) * (Number(tx.quantidade_taxa) || 0)) +
                  (((Number(tx.valor_cobranca_final_taxa) || 0) ) * (Number(item.custo_fixo) || 0) / 100) +
                  (((Number(tx.valor_cobranca_final_taxa) || 0) ) * (Number(item.imposto) || 0) / 100)
                )
            ) }}</td> <!-- Resultado -->
          </tr>
        </tbody>
      </v-simple-table>
      <h3 v-if="equipamentos.length > 0">Equipamentos</h3>
      <v-simple-table  v-if="equipamentos.length > 0" >
        <thead>
          <tr>
            <th>Nome</th>
            <th>Valor Cobrança UND</th>
            <th>Qtd.</th>
            <th>Desconto %</th>
            <th>Acrescimo %</th>
            <th>Valor Cobrança Final</th>
            <th>Custo do Equipamento UND</th>
            <th>Custo do Equipamento Final</th>
            <th>Custo Administrativo</th>
            <th>Custo Imposto</th>
            <th>Custo Total</th>
            <th>Resultado</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="equ in equipamentos" :key="equ.id">
            <td> {{ equ.equipamento.equipamento.nome }}</td> <!-- Nome -->
            <td> {{ formatReal((Number(equ.valor_cobranca_equipamento) || 0) ) }} </td> <!-- Valor Cobrança UND -->
            <td> {{ equ.quantidade_equipamento }}</td> <!-- Qtd -->
            <td> {{ equ.desconto_equipamento }}</td> <!-- Desconto-->
            <td> {{ equ.acrescimo_equipamento }}</td> <!-- Acrescimo -->
            <td> {{ formatReal((Number(equ.valor_cobranca_final_equipamento) || 0) ) }} </td> <!-- Valor Cobrança Final -->
            <td> {{ formatReal((Number(equ.equipamento.equipamento.custo) || 0) ) }} </td> <!-- Custo do Equipamento  UND-->
            <td> {{ formatReal((Number(equ.equipamento.equipamento.custo) || 0) * (Number(equ.quantidade_equipamento) || 0)) }} </td> <!-- Custo do Equipamento -->
            <td> {{ formatReal(((Number(equ.valor_cobranca_final_equipamento) || 0) ) * (Number(item.custo_fixo) || 0) / 100) }}</td> <!-- Custo Administrativo -->
            <td> {{ formatReal(((Number(equ.valor_cobranca_final_equipamento) || 0) ) * (Number(item.imposto) || 0) / 100) }}</td> <!-- Custo Imposto -->
            <td>
              {{ formatReal(
                ((Number(equ.equipamento.equipamento.custo) || 0) * (Number(equ.quantidade_equipamento) || 0)) +
                (((Number(equ.valor_cobranca_final_equipamento) || 0) ) * (Number(item.custo_fixo) || 0) / 100) +
                (((Number(equ.valor_cobranca_final_equipamento) || 0) ) * (Number(item.imposto) || 0) / 100)
              ) }}
            </td> <!-- Custo Total -->
            <td>
              {{ formatReal(
                ((Number(equ.valor_cobranca_final_equipamento) || 0) ) -
                (
                  ((Number(equ.equipamento.equipamento.custo) || 0) * (Number(equ.quantidade_equipamento) || 0)) +
                  (((Number(equ.valor_cobranca_final_equipamento) || 0) ) * (Number(item.custo_fixo) || 0) / 100) +
                  (((Number(equ.valor_cobranca_final_equipamento) || 0) ) * (Number(item.imposto) || 0) / 100)
                )
              ) }}
            </td> <!-- Resultado -->
          </tr>

        </tbody>
      </v-simple-table>
      <h3 v-if="matmed.length > 0" >Material / Medicamento / Dieta</h3>
      <v-simple-table v-if="matmed.length > 0" >
        <thead>
          <tr>
            <th>Nome</th>
            <th>Valor Cobrança UND</th>
            <th>Qtd.</th>
            <th>Desconto %</th>
            <th>Acrescimo %</th>
            <th>Valor Cobrança Final</th>
            <th>Custo do Matmed/Dieta UND</th>
            <th>Custo do Matmed/Dieta</th>
            <th>Custo Administrativo</th>
            <th>Custo Imposto</th>
            <th>Custo Total</th>
            <th>Resultado</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="mat in matmed" :key="mat.id">
            <td> {{ mat.matmed.nome }}</td> <!-- Nome -->
            <td> {{ formatReal((Number(mat.valor_cobranca_matmed) || 0) ) }} </td> <!-- Valor Cobrança Und -->
            <td> {{ mat.quantidade_matmed }}</td> <!-- Qtd -->
            <td> {{ mat.desconto_matmed }}</td> <!-- Desconto-->
            <td> {{ mat.acrescimo_matmed }}</td> <!-- Acrescimo -->
            <td> {{ formatReal((Number(mat.valor_cobranca_final_matmed) || 0) ) }} </td> <!-- Valor Cobrança Final -->
            <td> {{ formatReal((Number(mat.matmed.custo) || 0)) }} </td> <!-- Custo do Equipamento UND -->
            <td> {{ formatReal((Number(mat.matmed.custo) || 0) * (Number(mat.quantidade_matmed) || 0)) }} </td> <!-- Custo do Equipamento -->
            <td> {{ formatReal(((Number(mat.valor_cobranca_final_matmed) || 0) ) * (Number(item.custo_fixo) || 0) / 100) }}</td> <!-- Custo Administrativo -->
            <td> {{ formatReal(((Number(mat.valor_cobranca_final_matmed) || 0) ) * (Number(item.imposto) || 0) / 100) }}</td> <!-- Custo Imposto -->
            <td>
              {{ formatReal(
                ((Number(mat.matmed.custo) || 0) * (Number(mat.quantidade_matmed) || 0)) +
                (((Number(mat.valor_cobranca_final_matmed) || 0) ) * (Number(item.custo_fixo) || 0) / 100) +
                (((Number(mat.valor_cobranca_final_matmed) || 0) ) * (Number(item.imposto) || 0) / 100)
              ) }}
            </td> <!-- Custo Total -->
            <td>
              {{ formatReal(
                ((Number(mat.valor_cobranca_final_matmed) || 0) ) -
                (
                  ((Number(mat.matmed.custo) || 0) * (Number(mat.quantidade_matmed) || 0)) +
                  (((Number(mat.valor_cobranca_final_matmed) || 0) ) * (Number(item.custo_fixo) || 0) / 100) +
                  (((Number(mat.valor_cobranca_final_matmed) || 0) ) * (Number(item.imposto) || 0) / 100)
                )
              ) }}
            </td> <!-- Resultado -->
          </tr>

        </tbody>
      </v-simple-table>
    </v-col>
  </v-row>
</template>

<script>
import api from "../../http";

export default {
  name: "Expand",
  props: {
    item: Object,
  },
  data: () => ({
    isPageLoading: false,
    loadingEvolcuoes: false,
    loadingEquipamento : false,
    loadingTaxas : false,
    loadingMatmed: false,
    evolucoes: [],
    equipamentos: [],
    taxas: [],
    matmed: [],
  }),
  methods: {
    async getEvolcuoes() {
      this.loadingEvolcuoes = true;
      try {
        const { data } = await api.get(`atendimentos/evolucoes/?sessao=${this.item.id}`);
        this.evolucoes = data.filter(item => item.ativo && (item.assinatura !== null || item.procedimento.especial || item.procedimento.avaliacao));
      } catch (error) {
        alert("Erro ao listar Procedimentos");
        console.error(error);
      } finally {
        this.loadingEvolcuoes = false;
      }
    },
    async getEquipamento() {
      this.loadingEquipamento = true;
      try {
        const { data } = await api.get(`atendimentos/equipamentos/?orcamento=${this.item.id}`);
        this.equipamentos = data.filter(item => item.ativo);
        // console.log(this.equipamentos)
      } catch (error) {
        alert("Erro ao listar Equipamentos");
        console.error(error);
      } finally {
        this.loadingEquipamento = false;
      }
    },
    async getTaxas() {
      this.loadingTaxas = true;
      try {
        const { data } = await api.get(`atendimentos/taxas/?orcamento=${this.item.id}`);
        this.taxas = data.filter(item => item.ativo);
        // console.log(this.taxas)
      } catch (error) {
        alert("Erro ao listar Taxas");
        console.error(error);
      } finally {
        this.loadingTaxas = false;
      }
    },
    async getMatmed() {
      this.loadingMatmed = true;
      try {
        const { data } = await api.get(`atendimentos/matmeds/?orcamento=${this.item.id}`);
        this.matmed = data.filter(item => item.ativo);
        // console.log(this.matmed)
      } catch (error) {
        alert("Erro ao listar Matmed");
        console.error(error);
      } finally {
        this.loadingMatmed = false;
      }
    },
    formatReal(valor) {
      if (valor == null) return "R$ 0,00";
      return Number(valor).toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
    }
  },
  computed: {
    evolucoesAgrupadas() {
      const agrupados = {};

      for (const evo of this.evolucoes) {
        const key = [
          evo.procedimento?.procedimento,
          evo.valor_cobranca_final,
          evo.valor_pagamento
        ].join("|");

        if (!agrupados[key]) {
          agrupados[key] = {
            ...evo,
            quantidade: 1
          };
        } else {
          agrupados[key].quantidade += 1;
        }
      }

      return Object.values(agrupados);
    }
  },
  async mounted() {
    this.isPageLoading = true
    await this.getEvolcuoes();
    await this.getEquipamento()
    await this.getTaxas()
    await this.getMatmed()
    this.isPageLoading = false

  },
};
</script>
