<template>
  <v-container fluid @keydown.enter.prevent="pesquisar">
    <v-row>
      <v-col >
          <div class="title-container">
              <h1 class="h1">Analise de Rentabilidade</h1>
          </div>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12">
        <v-data-table :loading="isLoading('get')" :items="filteredData" :search="search" :headers="headersWithDynamicInfo"
         no-data-text="Selecione os campos desejados para a pesquisa e clique em 'PESQUISAR'" item-key="id" class="fixed-header-table" fixed-header  height="600px" :items-per-page="-1" :single-expand="singleExpand" :expanded.sync="expanded" show-expand >
         <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" class="py-4 grey lighten-2  text-start"> 
              <Expand :item="item" />
            </td>
          </template>
          <!-- Pesquisa e Filtros -->
          <template v-slot:top>
            <!-- Itens do campo de Pesquisa -->
            <div>
              <v-card dense class="mb-6"> 
                <h4 class="ml-4 mt-4" color></h4>
                <v-row dense class="py-2 px-4">
                  <v-col cols="2">
                    <v-text-field hide-details type="date" label="Data Início " v-model="filters.dateStart.value"
                      outlined dense clearable/>
                  </v-col>
                  <v-col cols="2">
                    <v-text-field hide-details type="date" label="Data Fim " v-model="filters.dateEnd.value"
                      outlined dense clearable/>
                  </v-col>
                  <v-col :cols="2">
                    <v-select hide-details label="Operadoras" :items="operadoraOptions" v-model="filters.operadora.value"
                      item-text="nome" item-value="id" outlined dense clearable/>
                  </v-col>
                  <v-col :cols="2">
                    <v-select hide-details label="Modalidade" :items="modalidadeOptions" v-model="filters.modalidade.value" 
                      item-text="nome" item-value="id" outlined dense clearable/>
                  </v-col>
                  <v-col cols="2">
                    <v-text-field hide-details label="ID Conta" v-model="filters.id.value"  type="Number" outlined dense clearable>
                    </v-text-field>
                  </v-col>
                  <v-col :cols="2">
                    <v-select hide-details label="Conta Aprovada" :items="fechamentoOptions" v-model="filters.ativo.value" 
                    item-text="nome" item-value="value" outlined dense clearable/>
                  </v-col>
                   <v-col cols="2">
                    <v-text-field hide-details label="Nome do Paciente" v-model="filters.nomepaciente.value" outlined dense clearable @input="formatPatientName"/>
                  </v-col>
                  <v-col class="text-end">
                    <v-btn color="gree" class="mr-1" elevation="0" @click="atualizar"> <v-icon left>mdi-reload </v-icon>Atualizar</v-btn>
                    <v-btn color="success" elevation="0" @click="applyFilters"> Pesquisar </v-btn>
                  </v-col>
                </v-row>   
              </v-card>
              <!-- Campo de Pesquisa -->
              <v-row dense>
                  <v-col cols="3" >
                    <v-text-field dense outlined v-model="search" label="Pesquisar" class="mx-0" append-icon="mdi-magnify" />
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col  class="text-end d-flex align-center justify-end">
                    <div class="d-flex align-center space-between">
                      <span class="faturado-text padded-text">Faturado: {{ valorTotalFiltrado | formatReal }}</span>
                      <span class="red-text padded-text"> Custo Ope.: {{ valorTotalCusto | formatReal }}</span>
                      <span class="red-text padded-text"> Custo Adm. ({{ custoAdmPercentual }}%) : {{ custoAdm | formatReal }}</span>
                      <span class="red-text padded-text"> Imposto ({{impostoPercentual}}%) : {{ custoImposto | formatReal }}</span>
                      <span class="faturado-text padded-text"> Resultado Esperada ({{rentabilidadePercentual}}%): {{ resultadoEsperado | formatReal }}</span>
                      <span :class="['faturado-text', 'padded-text', resultadoClass]"> Resultado Efetivo : {{ resultado | formatReal }} </span>
                      <span :class="['faturado-text', 'padded-text', percentualGlobalporcentagem]"> % Resultado: {{ percentualGlobal.toFixed(2) }} </span>
                    </div>
                  </v-col>
                </v-row>
            </div>
          </template>

          <template v-slot:[`item.data_inicio`]="{ item }">
            <span v-if="item.data_inicio">
              {{ item.data_inicio | formatDatabr }} até {{ item.data_fim | formatDatabr }}
            </span>
          </template>
          
          <template v-slot:[`item.fatorcamento`]="{ item }">
            {{ Number(item.fatorcamento) | formatReal }}
          </template>
          <template v-slot:[`item.fatrealizado`]="{ item }">
            {{ item.fatrealizado | formatReal }}
          </template>
          <template v-slot:[`item.imposto`]="{ item }">
            {{ (item.fatrealizado *(item.imposto / 100)) | formatReal }}
          </template>
          <template v-slot:[`item.custo`]="{ item }">
            {{ (item.fatrealizado *(item.custo_fixo / 100)) | formatReal }}
          </template>
          <template v-slot:[`item.custo_equipe`]="{ item }">
            {{ ((Number(item.taxa_custo) + Number(item.equipamento_custo) + Number(item.matmed_custo) + Number(item.procedimentos_final_custo))) | formatReal }}
          </template>
          <template v-slot:[`item.realizado`]="{ item }">
              {{
                calcularResultadoItem(item) | formatReal
              }}
            </template>

          <template v-slot:[`item.selectMultiple`]="{ item }">
            <v-checkbox
              v-model="selectedBudgets"
              :value="item.id"
              :disabled="isCheckboxDisabled(item)"
              @change="handleMultipleSelectionsChange"
            ></v-checkbox>
          </template>

          <template v-slot:[`item.ativo`]="{ item }">
            <span v-if="item.ativo">
              Aprovado
            </span>
            <span v-else> Em Análise</span>
          </template>
          <template v-slot:[`item.copiar`]="{ item }">
            <v-checkbox v-model="lista_items" :value="item.id"></v-checkbox>
          </template>
          <template v-slot:[`item.select_budget`]="{ item }">
            <div class="d-flex align-center justify-center">
              <v-checkbox 
              v-model="multipleSelections"
              :value="item.id"
              :disabled="isCheckboxDisabled(item)"
              @change="handleMultipleSelectionsChange"
              ></v-checkbox>
            </div>
          </template>
          <!-- <template v-slot:[`item.id`]="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on" :style="{ color: 
                                                              item.senha !== null &&
                                                              item.nr_guia_operador !== null &&
                                                              item.nr_guia_prestador !== null &&
                                                              item.senha_data_inicio !== null &&
                                                              item.senha_data_validade !== null
                                                              ? 'green' : 'red' }">{{ item.id }}</span>
              </template>
              <span v-if="item.senha !== null && 
                          item.nr_guia_operador !== null &&
                          item.nr_guia_prestador !== null &&
                          item.senha_data_inicio !== null &&
                          item.senha_data_validade !== null">Guia Completa</span>
              <span v-else>Guia Incompleta</span>
            </v-tooltip>
          </template> -->
        </v-data-table>
      </v-col>
    </v-row>
   
  </v-container>
</template>

<script>
import api from "@/http";
import UtilsFunc from "../../service/utilsFunc";
import '../../assets/css/styles.css';
import { mapGetters } from "vuex";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import Expand from './Expand.vue';

const { withCRUDUtils } = UtilsFunc
export default {
  name: "AnaliseRentabilidade",
  props: {
    id: { type: null }
  },
  components:{
    Expand,
  },
  data: () => withCRUDUtils({
    dialog: {
      clone: false,
      agrupado: false,
    },
    requiredField: [value => !!value || 'Campo obrigatório'],
    errorMessage: 'Campo obrigatório',
    modalidadeOptions: [],
    operadoraOptions: [],
    manutencaoOptions: [
      { nome: "SIM", value: '0' },
      { nome: "NÃO", value: '1' },
    ],
    fechamentoOptions: [
      { nome: "Aprovada", value: '1' },
      { nome: "Em Análise", value: '0' },
    ],
    operadora_: "",
    search: "",
    paciente_: "",
    data_inicio: '',
    data_fim: '',
    modalidades: [],
    activeFilters: [],
    menu: false,
    faturamento: true, 
    formValid: false,
    data_envio_faturamento: '',
    protocolo_operadora: '',
    filters: {
      dateStart: { value: '', compareType: 'gte', prop: 'data_inicio' },
      dateEnd: { value: '', compareType: 'lte', prop: 'data_fim' },
      dateStartFatura: { value: '', compareType: 'gte', prop: 'data_envio_faturamento' },
      dateEndFatura: { value: '', compareType: 'lte', prop: 'data_envio_faturamento' },
      modalidade: { value: '', compareType: 'equal', prop: 'modalidade_o.id' },
      operadora: { value: '', compareType: 'equal', prop: 'operadora_o.id' },
      manutencao: { value: '', compareType: 'equal', prop: 'manutencao' },
      fechamento: { value: '', compareType: 'equal', prop: 'fechamento' },
      nomepaciente: { value: '', compareType: 'includes', prop: 'paciente' },
      id: { value: '', compareType: 'includes', prop: 'id' },
      ativo: { value: '', compareType: 'includes', prop: 'ativo' },
    },
    newItem: {},
    showFilters: false,
    pacienteId: 0,
    operadoras: [],
    pacientes: [],
    lista_items: [],
    budgets: [],
    selectedBudgets: [], 
    multipleSelections: [],
    loading: false,
    displayedData: [],
    singleExpand: false,
    expanded: [],
    headers: [
      { text: "Selecionar Conta", align: "center", sortable: false, value: "select_budget", width: 60 },
      { text: "Nrº Orçamento / Conta", class: "font-weight-black", align: "center", sortable: true, value: "id" },
      { text: "Status", align: "center", sortable: true, value: "ativo" },
      { text: "Convênio", align: "center", sortable: true, value: "convenio" },
      { text: "Paciente", align: "start", sortable: true, value: "paciente" , width: 250},
      { text: "Modalidade", align: "start", sortable: true, value: "modalidade_o.nome"},
      { text: "Data Orçamento", align: "center", sortable: true, value: "data_inicio" },
      { text: "Valor do Orçamento", align: "center", sortable: true, value: "fatorcamento" },
      { text: "Valor da Conta Faturado ", align: "center", sortable: true, value: "fatrealizado" },
      { text: "Valor da Custo Equipe ", align: "center", sortable: true, value: "custo_equipe" },
      { text: "Valor da Imposto ", align: "center", sortable: true, value: "imposto" },
      { text: "Valor da Custo ADM ", align: "center", sortable: true, value: "custo" },
      { text: "Valor do Resultado ", align: "center", sortable: true, value: "realizado" },
     
    ],
    selectedOrcamentoId: null,
    idsEvolucoes: [],
    selectedOrcamentoDtInicio: null,
    selectedOrcamentoDtFim: null,
    filteredDatas:[],
    isLoadingEvolucoes: false,
  }),
  methods: {
    async get_listas(url, lista) {
      try {
        const response = await api.get(url);
        if (response.status === 200) {
          if (response.data instanceof Array && response.data.length) {
            // Ordena o array com base no campo 'nome'
            response.data.sort((a, b) => {
              if (a.nome < b.nome) {
                return -1;
              }
              if (a.nome > b.nome) {
                return 1;
              }
              return 0;
            });

            // Filtra o array para manter apenas os elementos com 'ativo' igual a true
            const filteredData = response.data.filter(item => item.ativo === true);
            
            // Atualiza a lista com os dados filtrados
            this[lista] = filteredData;
          }
        }
      } catch (error) {
        console.error("Erro ao buscar lista:", error);
      }
    },
    async getOperadoras() {
      try {
        const { data } = await api.get('operadoras/operadoras/');
        this.operadoras = data
          .filter(operadora => operadora && operadora.ativo)
          .sort((a, b) => a.nome.localeCompare(b.nome));
      } catch (error) {
        alert("Erro ao listar as Operadoras")
        console.log(error)
      }
    },
    async getModalidades() {
      try {
        const { data } = await api.get('uteis/modalidades/');
        this.modalidades = data
          .filter(modalidade => modalidade.ativo)
          .sort((a, b) => a.nome.localeCompare(b.nome));
      } catch (error) {
        alert("Erro ao listar as Operadoras")
        console.log(error)
      }
    },
    async getPacientes() {
      try {
        const { data } = await api.get(`pacientes/listar/?operadora=${this.newItem.operadora.id}&ativo=1&empresa=${this.companyData.id}`);
        this.pacientes = data.sort((a, b) => a.nome.localeCompare(b.nome));
      } catch (error) {
        alert("Erro no link ao solicitar equipe");
      }
    },
    async getChildrensOperadoras() {
      this.getPacientes();
    },
    updateDisplayedData() {
  let filtered = [...this.tableData];

  // Filtro manual do "ativo" (true ou false)
  if (this.filters.ativo.value !== '') {
    const ativoValue = this.filters.ativo.value === '1'; // Se você usa '1' e '0' na opção
    filtered = filtered.filter(item => item.ativo === ativoValue);
  }

  // Aplica os demais filtros, exceto o 'ativo'
  const filtersSemAtivo = this.activeFilters.filter(f => f.prop !== 'ativo');
  filtered = filtered.filter(UtilsFunc.createFilter(filtersSemAtivo));

  // Aplicar busca textual (campo "Pesquisar")
  if (this.search) {
    const query = this.search.toLowerCase();
    filtered = filtered.filter(item => {
      const itemString = [
        item.id,
        item.convenio,
        item.paciente,
        item.modalidade,
        item.senha,
        item.nr_guia_operador,
        item.nr_guia_prestador,
        item.paciente_id,
        item.protocolo_operadora,
      ].join(' ').toLowerCase();
      return itemString.includes(query);
    });
  }

  // Atribui o resultado à `displayedData`
  this.displayedData = filtered.map(item => ({
    ...item,
    fatorcamentoFormatted: item.fatorcamento,
    fatrealizadoFormatted: item.fatrealizado
  }));
}
,
    async getSessions() {
      const LOADING_NAME = 'get';
      this.setLoading(LOADING_NAME);
      try {
        const params = new URLSearchParams();
        params.append('empresa', this.companyData.id);

        if (this.filters.operadora.value) {
          params.append('operadora', this.filters.operadora.value);
        }

        if (this.filters.modalidade.value) {
          params.append('modalidade', this.filters.modalidade.value);
        }

        if (this.filters.nomepaciente.value) {
          params.append('nomepaciente', this.filters.nomepaciente.value);
        }

        if (this.filters.dateStart.value) {
          params.append('date_start', this.filters.dateStart.value);
        }

        if (this.filters.dateEnd.value) {
          params.append('date_end', this.filters.dateEnd.value);
        }
        if (this.filters.id.value) {
          params.append('id', this.filters.id.value);
          }

        const url = `atendimentos/orcamentos/?${params.toString()}`;

        const { data } = await api.get(url);
        this.tableData = data
          // .filter(e => e.ativo)
          .map(e => {
            try {
              e['modalidade_o'] = typeof e.modalidade === 'string' ? JSON.parse(e.modalidade) : e.modalidade;
              e['operadora_o'] = typeof e.operadora_obj === 'string' ? JSON.parse(e.operadora_obj) : e.operadora_obj;
              e['paciente_o'] = typeof e.paciente_obj === 'string' ? JSON.parse(e.paciente_obj) : e.paciente_obj;
            } catch (err) {
              console.error("Erro ao parsear JSON", err);
            }
            return e;
          })
          .sort((a, b) => new Date(b.data_inicio) - new Date(a.data_inicio));

        this.updateDisplayedData();

      } catch (error) {
        this.$errorHandler(error);
        this.$toast.error(`Erro ao buscar sessões: ${error}`);
      } finally {
        this.setLoading(LOADING_NAME, true);
      }
    },
    async confirmarFaturamento() {
      const LOADING_NAME = 'put:sessaoInformacao';
      this.setLoading(LOADING_NAME);
      try {
        for (let sessionId of this.multipleSelections) {
          let sessionData = this.tableData.find(item => item.id === sessionId);

          let hospitalData = JSON.parse(sessionData.hospital || '{}'); // Parse o JSON ou use um objeto vazio

          if (hospitalData && Object.keys(hospitalData).length > 0) {
              sessionData.hospital_o = hospitalData; // Define hospital_o como o objeto completo
              sessionData.hospital = hospitalData.id; // Define hospital como o ID do hospital
          } else {
              delete sessionData.hospital_o; // Remove hospital_o se não houver dados
              delete sessionData.hospital; // Remove hospital se não houver dados
          }

          let motivo_suspensao_atendimentoData = JSON.parse(sessionData.motivo_suspensao_atendimento || '{}'); // Parse o JSON ou use um objeto vazio

          if (motivo_suspensao_atendimentoData && Object.keys(motivo_suspensao_atendimentoData).length > 0) {
              sessionData.motivo_suspensao_atendimento_o = motivo_suspensao_atendimentoData; // Define motivo_suspensao_atendimento_o como o objeto completo
              sessionData.motivo_suspensao_atendimento = motivo_suspensao_atendimentoData.id; // Define motivo_suspensao_atendimento como o ID do motivo_suspensao_atendimento
          } else {
              delete sessionData.motivo_suspensao_atendimento_o; // Remove motivo_suspensao_atendimento_o se não houver dados
              delete sessionData.motivo_suspensao_atendimento; // Remove motivo_suspensao_atendimento se não houver dados
          }

          const fields = {
              ...sessionData,
              pacote: this.pacote_id,
              data_envio_faturamento: this.data_envio_faturamento,
              protocolo_operadora: Number(this.protocolo_operadora),

          };
          await this.updateSession(fields);
        }
        this.dialog.agrupado = false;
        this.$toast.success('Informações de faturamento salvas com sucesso.');
      } catch (error) {
        this.$toast.error(`Erro ao salvar as informações de faturamento. ${error}`);
      } finally {
        this.setLoading(LOADING_NAME, true);
      }
    },
    clearFilters() {
      Object.keys(this.filters).forEach((prop) => this.filters[prop].value = '')
      this.activeFilters = []
      this.updateDisplayedData();
      this.dateStartError = false;
      this.dateEndError = false;
      this.search = '';
      this.multipleSelections = [];
      this.selectedBudgets = [];
      this.getSessions();
    },
    clearSelecao(){
      this.selectedBudgets = [];
      this.multipleSelections = [];
    },
    applyFilters() {
      this.selectedBudgets = [];
      this.multipleSelections = [];

      const areAllFiltersEmpty = Object.values(this.filters).every(filter => !filter.value);
      if (areAllFiltersEmpty) {
        this.$toast.error('Por favor, escolha pelo menos um critério de pesquisa.');
        return;
      }

      this.activeFilters = Object.values(this.filters)
        .filter(f => f.value !== '' && f.value !== null && f.prop !== 'ativo');

      this.getSessions(); // Agora ela vai buscar os dados com os filtros corretos
    },
    pesquisar() {
      this.applyFilters()
    },
    validateSameOperadora(selectedIds) {
      // Retorna true se não houver orçamentos selecionados (primeira seleção é sempre válida)
      if (selectedIds.length <= 1) return true;

      // Obtém a operadora do primeiro item selecionado para comparação
      const firstSelectedOperadora = this.tableData.find(item => item.id === selectedIds[0]).operadora_o.id;
      
      // Verifica se todos os orçamentos selecionados têm a mesma operadora
      return selectedIds.every(id => {
        const itemOperadora = this.tableData.find(item => item.id === id).operadora_o.id;
        return itemOperadora === firstSelectedOperadora;
      });
    },
    handleMultipleSelectionsChange(value) {
      if (!this.validateSameOperadora(value)) {
        this.$toast.error('Todos os orçamentos selecionados devem ser da mesma operadora.');
        // Remove o último item selecionado que causou a falha na validação
        this.selectedBudgets.pop();
        return;
      }
  
      this.selectedBudgets = value;
      // Outras operações necessárias...
    },
    isCheckboxDisabled(item) {
      // Se não houver itens selecionados, nenhuma caixa de seleção deve ser desabilitada
      if (this.multipleSelections.length === 0) return false;

      // Obtém a operadora do primeiro item selecionado
      const firstSelectedOperadora = this.tableData.find(data => data.id === this.multipleSelections[0]).operadora_o.id;

      // Desabilita a caixa de seleção se a operadora do item atual for diferente da dos itens já selecionados
      return item.operadora_o.id !== firstSelectedOperadora;
    },
   
    calcularDatasDinamicas() {
      // Obter o primeiro dia do mês anterior
      const today = new Date();
      const ontem = new Date(today);
      ontem.setDate(today.getDate() - 1);
      const primeiroDiaDoMesAtual = new Date(today.getFullYear(), today.getMonth(), 1);
      const primeiroDiaDoMesAnterior = new Date(primeiroDiaDoMesAtual);
      primeiroDiaDoMesAnterior.setMonth(primeiroDiaDoMesAtual.getMonth() - 4);

      // Formatar as datas no formato YYYY-MM-DD
      this.filters.dateStart.value = `${primeiroDiaDoMesAnterior.getFullYear()}-${(primeiroDiaDoMesAnterior.getMonth() + 1).toString().padStart(2, '0')}-${primeiroDiaDoMesAnterior.getDate().toString().padStart(2, '0')}`;
      this.filters.dateEnd.value = `${ontem.getFullYear()}-${(ontem.getMonth() + 1).toString().padStart(2, '0')}-${ontem.getDate().toString().padStart(2, '0')}`;
    },
    async atualizar() {
      // Limpa todos os filtros
      Object.keys(this.filters).forEach(key => {
        this.filters[key].value = '';
      });

      this.activeFilters = [];
      this.search = '';
      this.selectedBudgets = [];
      this.multipleSelections = [];
      this.displayedData = [];
      this.tableData = [];

      // Recarrega listas
      await Promise.all([
        this.get_listas('operadoras/app-operadoras/', 'operadoraOptions'),
        this.get_listas('uteis/app-modalidades/', 'modalidadeOptions'),
        this.getOperadoras(),
        this.getModalidades()
      ]);

      // Recalcula as datas padrão
      this.calcularDatasDinamicas();

      // Atualiza a tela (sem buscar dados ainda)
      this.updateDisplayedData();
    },
    calcularValorTotal() {
      return this.filteredData.reduce((total, item) => {
        return total + parseFloat(item.fatrealizado || 0);
      }, 0);
    },
    calcularCusto() {
      console.log(this.filteredData)
      return this.filteredData.reduce((total, item) => {
        return total + 
          parseFloat(item.procedimentos_final_custo || 0) + 
          parseFloat(item.taxa_custo || 0) + 
          parseFloat(item.matmed_custo || 0) + 
          parseFloat(item.equipamento_custo || 0);
      }, 0);
    },
    calcularValorTotalRecebido() {
      return this.filteredData.reduce((total, item) => {
        if (item.data_pag_operadora) {
          return total + (parseFloat(item.fatrealizado || 0) - parseFloat(item.valor_glosado || 0));
        }
        return total;
      }, 0);
    },
    calcularValorTotalGlosado() {
      return this.filteredData.reduce((total, item) => {
        return total + parseFloat(item.valor_glosado || 0);
      }, 0);
    },
    percentualGlosa() {
      const valorFaturado = this.valorTotalFiltrado;
      const valorGlosado = this.valorTotalCusto;
      if (valorFaturado === 0) {
        return 0;
      }
      return 100 - ((valorGlosado / valorFaturado) * 100);
    },
    exportToExcel() {
      try {
        const worksheet = XLSX.utils.json_to_sheet(this.filteredData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Contas');

        const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        saveAs(new Blob([wbout], { type: 'application/octet-stream' }), 'contas.xlsx');
      } catch (error) {
        console.error('Erro ao exportar para Excel:', error);
      }
    },
    collapseAll() {
      this.expanded = [];
    },
    calcularResultadoItem(item) {
      const fatrealizado = Number(item.fatrealizado) || 0;
      const custoFixo = Number(item.custo_fixo) || 0;
      const imposto = Number(item.imposto) || 0;
      const taxaCusto = Number(item.taxa_custo) || 0;
      const equipamentoCusto = Number(item.equipamento_custo) || 0;
      const matmedCusto = Number(item.matmed_custo) || 0;
      const procedimentosCusto = Number(item.procedimentos_final_custo) || 0;

      const custoAdm = fatrealizado * (custoFixo / 100);
      const impostoValor = fatrealizado * (imposto / 100);
      const custoEquipe = taxaCusto + equipamentoCusto + matmedCusto + procedimentosCusto;

      return fatrealizado - (custoAdm + impostoValor + custoEquipe);
    },
    formatPatientName() {
      if (this.filters && this.filters.nomepaciente && this.filters.nomepaciente.value) {
        const lowercaseWords = ["a","e", "i", "o", "u", "de", "des", "da", "das", "do", "dos"]; // Palavras que devem permanecer em minúsculas
        this.filters.nomepaciente.value = this.filters.nomepaciente.value
          .toLowerCase()
          .split(" ")
          .map(word => {
            return lowercaseWords.includes(word) ? word : word.charAt(0).toUpperCase() + word.slice(1);
          })
          .join(" ");
      }
    },

  },
  mounted() {
    this.get_listas('operadoras/app-operadoras/', 'operadoraOptions')
    this.get_listas('uteis/app-modalidades/', 'modalidadeOptions')
    this.getOperadoras()
    this.getModalidades()
    this.calcularDatasDinamicas();
    this.updateDisplayedData();
    
  },
  computed: {
    ...mapGetters(['companyData']),
    paciente() {
      return this.pacientes.find(p => p.id === this.pacienteId) || {}
    },
    filteredData() {
    // Verifica se há algum filtro ativo ou se o campo de pesquisa não está vazio
    const isFilterApplied = this.activeFilters.length > 0;
    const isSearchApplied = this.search.trim().length > 0;

    // Retorna os dados filtrados apenas se um filtro ou pesquisa estiver ativo
    if (isFilterApplied || isSearchApplied) {
      return this.displayedData;
    }

    // Retorna um array vazio se nenhum filtro ou pesquisa estiver ativo
    return [];
  },
  
    isContaFechadaXML() {
      if (this.multipleSelections.length > 0) {
        const selectedOrcamento = this.tableData.find(item => item.id === this.multipleSelections[0]);
        return selectedOrcamento && selectedOrcamento.fechamento === '1';
      }
      // console.log(this.multipleSelections)
      return false;
    },
    
     headersWithDynamicInfo() {
      // Clona o array de headers para não modificar o original
      const headers = this.headers.map(header => ({ ...header }));

      // Encontra o índice do cabeçalho "Nrº Orçamento / Conta"
      const nrOrcamentoIndex = headers.findIndex(header => header.value === 'id');
      if (nrOrcamentoIndex !== -1 && this.guiasIncompletas > 0) {
        headers[nrOrcamentoIndex].text = `Nrº Orçamento / Conta (${this.guiasIncompletas} guias incompletas)`;
      }

      const xmlAgrupadoIndex = headers.findIndex(header => header.text.includes('Gerar XML AGRUPADO'));
      if (xmlAgrupadoIndex !== -1) {
        if (this.multipleSelections.length === 1) {
          headers[xmlAgrupadoIndex].text = `Gerar XML AGRUPADO (${this.multipleSelections.length} conta)`;
        } else if (this.multipleSelections.length > 1) {
          headers[xmlAgrupadoIndex].text = `Gerar XML AGRUPADO (${this.multipleSelections.length} contas)`;
        } else {
          headers[xmlAgrupadoIndex].text = 'Gerar XML AGRUPADO'; // Sem seleções
        }
      }
      return headers;
    },

    valorTotalRecebido() {
      return this.calcularValorTotalRecebido();
    },
    valorTotalFiltrado() {
      const data = this.selectedBudgets.length > 0
        ? this.tableData.filter(item => this.selectedBudgets.includes(item.id))
        : this.filteredData;

      return data.reduce((total, item) => total + parseFloat(item.fatrealizado || 0), 0);
    },

    valorTotalCusto() {
      const data = this.selectedBudgets.length > 0
        ? this.tableData.filter(item => this.selectedBudgets.includes(item.id))
        : this.filteredData;

      return data.reduce((total, item) => {
        return total +
          parseFloat(item.procedimentos_final_custo || 0) +
          parseFloat(item.taxa_custo || 0) +
          parseFloat(item.matmed_custo || 0) +
          parseFloat(item.equipamento_custo || 0);
      }, 0);
    },
    custoAdm() {
      const data = this.selectedBudgets.length > 0
        ? this.tableData.filter(item => this.selectedBudgets.includes(item.id))
        : this.filteredData;

      if (!data.length) return 0;

      const faturadoTotal = data.reduce((total, item) => {
        return total + parseFloat(item.fatrealizado || 0);
      }, 0);

      const percentual = parseFloat(data[0].custo_fixo || 0); // assumindo que todos têm o mesmo custo_fixo
      return faturadoTotal * (percentual / 100);
    },
    custoImposto() {
      const data = this.selectedBudgets.length > 0
        ? this.tableData.filter(item => this.selectedBudgets.includes(item.id))
        : this.filteredData;

      if (!data.length) return 0;

      const faturadoTotal = data.reduce((total, item) => {
        return total + parseFloat(item.fatrealizado || 0);
      }, 0);

      const percentual = parseFloat(data[0].imposto || 0); // assumindo que todos têm o mesmo custo_fixo
      return faturadoTotal * (percentual / 100);
    },
    resultadoEsperado() {
      const data = this.selectedBudgets.length > 0
        ? this.tableData.filter(item => this.selectedBudgets.includes(item.id))
        : this.filteredData;

      if (!data.length) return 0;

      const faturadoTotal = data.reduce((total, item) => {
        return total + parseFloat(item.fatrealizado || 0);
      }, 0);

      const percentual = parseFloat(data[0].rentabilidade || 0); // assumindo que todos têm o mesmo custo_fixo
      return faturadoTotal * (percentual / 100);
    },
    resultado() {
      const faturado = this.valorTotalFiltrado;
      const custo = this.valorTotalCusto;
      const custo_administrativo = this.custoAdm;
      const imposto = this.custoImposto;
      const custoBase = custo + custo_administrativo + imposto;

      return Math.abs(faturado - custoBase);
    },
    resultadoClass() {
      const faturado = this.valorTotalFiltrado;
      const custo = this.valorTotalCusto + this.custoAdm + this.custoImposto;

      return faturado >= custo ? 'green-text' : 'red-text';
    },

    valorTotalGlosado() {
      const data = this.selectedBudgets.length > 0
        ? this.tableData.filter(item => this.selectedBudgets.includes(item.id))
        : this.filteredData;

      return data.reduce((total, item) => total + parseFloat(item.valor_glosado || 0), 0);
    },

    percentualGlobal() {
      const faturado = this.valorTotalFiltrado;
      const custo = this.valorTotalCusto + this.custoAdm + this.custoImposto;
      if (faturado === 0) return 0;
      return (100 - ((custo / faturado) * 100));
    },
    percentualGlobalporcentagem() {
      const faturado = this.valorTotalFiltrado;
      const custo = this.valorTotalCusto + this.custoAdm + this.custoImposto;
      return faturado >= custo ? 'green-text' : 'red-text';
     
    },
    custoAdmPercentual() {
      const data = this.selectedBudgets.length > 0
        ? this.tableData.find(item => item.id === this.selectedBudgets[0])
        : this.filteredData[0];

      return data?.custo_fixo ?? null;
    },
    impostoPercentual() {
      const data = this.selectedBudgets.length > 0
        ? this.tableData.find(item => item.id === this.selectedBudgets[0])
        : this.filteredData[0];

      return data?.imposto ?? null;
    },
    rentabilidadePercentual() {
      const data = this.selectedBudgets.length > 0
        ? this.tableData.find(item => item.id === this.selectedBudgets[0])
        : this.filteredData[0];

      return data?.rentabilidade ?? null;
    },


  },
  watch: {
    fsearch() {
      this.updateDisplayedData();
    },
    activeFilters() {
      this.updateDisplayedData();
    },
    search() {
      this.updateDisplayedData();
    },
    filteredData(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.contaspendentes = newVal.filter(item => item.fechamento === '0').length;
      }
    },
  },

};
</script>
<style scoped>
.container {
  display: flex;
  width: 100%;
  gap: 0.2rem;
  flex-direction: column;
  background-color: white;
  /* align-items: center; */
}
.sem-evolucoes {
  color: red;
  text-align: center;
  /* Outras propriedades de estilo se necessário */
}
.com-evolucoes {
  color: green;
  text-align: center;
  /* Outras propriedades de estilo se necessário */
}
.title-border {
  border-bottom: 2px solid #E0E0E0 !important; 
  padding-bottom: 10px !important;
}
.title-container {
  display: flex;
  align-items: center;
  justify-content: center; /* Centraliza os elementos horizontalmente */
  flex-wrap: nowrap; /* Impede que os elementos sejam quebrados em linhas diferentes */
}

.h1 {
  margin: 0; /* Remove margens padrão para melhor alinhamento */
  flex-shrink: 0; /* Impede que o título encolha, forçando a badge a se mover para baixo */
}

.badge {
  background-color: #FAAF19 ;
  color: #0D518F;
  border-radius: 50%;
  /* padding: 5px 10px; */
  font-size: 0.8rem;
  margin-left: 10px; /* Espaço entre o título e a badge */
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 20px;
  height: 20px;
  flex-shrink: 0; /* Impede que a badge encolha */
}
.alert-message {
  color: red;
  margin-top: 10px;
  width: 300px;
  white-space: pre-wrap; 
}

.faturado-text {
  font-size: 1.3rem;
  font-weight: bold;
  color: green; /* Ajuste a cor conforme necessário */
  margin-top: 4px;
}
.glosado-text {
  font-size: 1.3rem;
  font-weight: bold;
  color: red; /* Ajuste a cor conforme necessário */
  margin-top: 4px;
}
.red-text {
  font-size: 1.3rem;
  font-weight: bold;
  color: red;
  margin-top: 4px;
}
.green-text {
  font-size: 1.3rem;
  font-weight: bold;
  color: green;
  margin-top: 4px;
}


.padded-text {
  padding-right: 20px; /* Ajuste o valor conforme necessário */
}
.space-between {
  display: flex;
  gap: 20px; /* Ajuste o valor conforme necessário */
  align-items: center;
}
.table-container {
  max-height: 500px; /* Define a altura máxima para o contêiner da tabela */
  overflow-y: auto;  /* Permite a rolagem vertical */
}

.fixed-header-table thead th {
  background-color: white; /* Cor de fundo do cabeçalho para que ele permaneça visível */
  position: sticky;
  top: 0;
  z-index: 1;
}

</style>